
import {defineComponent} from 'vue'
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import ReminderName from "@/views/reminder/ReminderName.vue";

export default defineComponent({
  name: "ReminderList",
  components: {ReminderName, DateTimeFormat},
  props: {
    items: {},
    isPanel: {default:false}
  }
})


import {computed, defineComponent, ref} from 'vue'
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import Pagination from "@/components/base/paging/Pagination.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import ReminderList from "@/views/reminder/ReminderList.vue";

export default defineComponent({
  name: "PendingReminder",
  components: {ReminderList, EntityLoading, Pagination},
  props: {
    object: {type: Object, required: true}
  },
  setup(props) {
    const objectFilter = ref(props.object);
    const page = computed(() => store.state.ReminderModule.pendingPage);
    return {
      page,
      ...LoadFilterObjects(Actions.LOAD_PENDING_REMINDER, objectFilter.value, ['assignee', 'team'])
    }
  }
})


import {computed, defineComponent, onMounted, onUpdated, ref} from 'vue'
import QuickAction from "@/components/base/action/QuickAction.vue";
import AllReminder from "@/views/reminder/AllReminder.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ReminderForm from "@/views/reminder/ReminderForm.vue";
import store from "@/store";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import Row from "@/components/base/common/Row.vue";
import {LoadPanel} from "@/core/composite/composite";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import PendingReminder from "@/views/reminder/PendingReminder.vue";
import CompleteReminder from "@/views/reminder/CompleteReminder.vue";
import CompleteReminderForm from "@/views/reminder/CompleteReminderForm.vue";
import Swal from "sweetalert2";
import ReminderService from "@/core/services/ReminderService";
import Audits from "@/views/audit/Audits.vue";

export default defineComponent({
  name: "Reminders",
  components: {
    Audits,
    CompleteReminderForm,
    CompleteReminder,
    PendingReminder, Row, Avatar, EntityLoading, ReminderForm, BaseModal, AllReminder, QuickAction
  },
  props: {
    object: {type: Object, required: true}
  },
  setup() {
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      console.log(link);
      if (link) {
        link.classList.remove("active")
      }
    })
    onMounted(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    const reminder = computed(() => store.state.ReminderModule.selectedReminder)
    store.commit(Mutations.SET_REMINDER, {show: false, data: {}, loading: true})

    const user = computed(() => store.getters.currentUser);
    const state = ref<any>({
      id: '',
      formName: '',
      reminder: {
        name: '',
        dueDt: '',
        description :'',
        claimIdRequest: '',
        assignType: 'USER',
        cazeIdRequest: '',
        assigneeIdRequest: '',
        teamIdRequest: ''
      }
    });
    return {
      user,
      reminder,
      state,
      ...LoadPanel(),
    }
  },
  methods: {
    onAdd() {
      switch (this.object.objectType) {
        case 'CLAIM':
          this.state.reminder.claimIdRequest = this.object.objectId
          this.state.reminder.cazeIdRequest = ''
          break
        case 'CASE':
          this.state.reminder.cazeIdRequest = this.object.objectId
          this.state.reminder.claimIdRequest = ''
          break
      }
      this.state.id = ''
      this.state.reminder.description
      this.state.reminder.name = ''
      this.state.reminder.dueDt = new Date().getTime();
      this.state.reminder.assignType = 'USER'
      this.state.reminder.assigneeIdRequest = this.user.id
      this.state.reminder.teamIdRequest = ''
      this.state.formName = 'New Reminder'
      const modal = this.$refs.reminderFormRef as typeof BaseModal;
      modal.showBaseModal();
    },

    onSaved(res) {
      const modal = this.$refs.reminderFormRef as typeof BaseModal;
      modal.hideBaseModal();
      this.reloadList();
      if (this.reminder?.data?.id == res.id) {
        store.dispatch(Actions.LOAD_REMINDER, this.reminder.data.id)
      }
    },

    reloadList() {
      const all = this.$refs["all-reminder"] as typeof AllReminder;
      all.paginationLoad()
      const pending = this.$refs["pendingReminder"] as typeof PendingReminder;
      pending.paginationLoad()
      const complete = this.$refs["completeReminder"] as typeof CompleteReminder;
      complete.paginationLoad()
    },

    onEdit(reminder: any) {
      this.state.id = reminder.id
      switch (this.object.objectType) {
        case 'CLAIM':
          this.state.reminder.claimIdRequest = this.object.objectId
          this.state.reminder.cazeIdRequest = ''
          break
        case 'CASE':
          this.state.reminder.cazeIdRequest = this.object.objectId
          this.state.reminder.claimIdRequest = ''
          break
      }
      this.state.reminder.name = reminder.name
      this.state.reminder.description  = reminder.description
      this.state.reminder.dueDt = reminder.dueDt
      this.state.reminder.assignType = reminder?.assignee?.id ? 'USER' : 'TEAM'
      this.state.reminder.assigneeIdRequest = reminder.assignee?.id
      this.state.reminder.teamIdRequest = reminder.team?.id
      this.state.formName = 'Update Reminder'
      const modal = this.$refs.reminderFormRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onComplete(id) {
      this.state.id = id
      const modal = this.$refs.reminderCompleteForm as typeof BaseModal;
      modal.showBaseModal();

    },
    onCompleted(res) {
      this.reloadList()
      store.dispatch(Actions.LOAD_REMINDER, res.id)
      const modal = this.$refs.reminderCompleteForm as typeof BaseModal;
      modal.hideBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ReminderService.delete(id).then(() => {
            this.reloadList()
            store.commit(Mutations.SET_REMINDER, {show: false, data: {}, loading: false})
          })
        }
      })
    }

  }
})
